import React, { lazy, Suspense, Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
//import axios from './axios-api';

import Layout from "./hoc/Layout/Layout";
import Logout from "./containers/Auth/Logout/Logout";
import Aux1 from "./hoc/Aux1/Aux1";
import * as actions from "./store/actions/index";
// import CacheBuster from './CacheBuster';
import CacheBuster from "react-cache-buster";
// import { DataTable } from "primereact/datatable";
import { version } from "../package.json";
import packageJson from "../package.json";

import packageInfo from "../package.json";
import Spinner from "./components/UI/Spinner/Spinner";
global.appVersion = packageJson.version;


const Launch = lazy(() => import("./components/Launch/Launch"));

const Trip = lazy(() => import("./components/Trip/Trip"));
const TripView = lazy(() => import("./components/TripView/Trip"));
const MyRequests = lazy(() => import("./components/MyRequest/MyRequest"));
const AllRequests = lazy(() => import("./components/AllRequests/AllRequests"));

const Login = lazy(() => import("./components/Launch/Login"));

const windowUrl = window.location.hash;
let paramString = windowUrl.split("?")[1];
let isprint = false;
if(paramString !== undefined)
{
  isprint = paramString.includes("print");
}
// let printstring = window.location.pathname;
let printid = "";
localStorage.removeItem("printid");

if (paramString !== undefined && !isprint) {
  var queryParams = paramString.split("&").reduce(function (q, query) {
    var data = paramString.substring(3);
    var value = decodeURIComponent(data);
    value = isNaN(Number(value)) ? value : Number(value);
    if (value !== undefined) localStorage.setItem("itstoken", value);

    return value;
  }, {});
} else if (paramString !== undefined && isprint) {
  // printid = window.location.search.split("?")[1];
   var data = paramString.substring(6);
    var value = decodeURIComponent(data);
    if (value !== undefined) localStorage.setItem("printid", value);
    printid = value;
   
  // localStorage.setItem("printid", printid);
}

// const semverGreaterThan = (versionA, versionB) => {
//   const versionsA = versionA.split(/\./g);

//   const versionsB = versionB.split(/\./g);
//   while (versionsA.length || versionsB.length) {
//     const a = Number(versionsA.shift());

//     const b = Number(versionsB.shift());
//     // eslint-disable-next-line no-continue
//     if (a === b) continue;
//     // eslint-disable-next-line no-restricted-globals
//     return a > b || isNaN(b);
//   }
//   return false;
// };

class App extends Component {
  componentDidMount() {
    if (printid === "") {
      this.props.onTryAutoSignup();
      if (localStorage.itstoken !== "" && localStorage.itstoken !== undefined) {
        this.props.onOneLoginSignup();
      }
    }
  }

  render() {
    let routes = (
      <Suspense fallback={<Spinner></Spinner>}>
        <Switch>
          <Route
            path="/"
            exact
            component={
              paramString !== undefined || printid !== "" ? null : Login
            }
          />
          {/* //uncomment on prod */}
          {/* <Route path="/requests" exact component={MyRequests} /> */}
          {/* <Route path="/info" component={Landingpage} /> */}
          <Route path="/login" exact component={Login} />
          {/* <Route path="/events" component={events} /> */}
        </Switch>
      </Suspense>
    );

    if (this.props.isAuthenticated) {
      localStorage.removeItem("itstoken");
      routes = (
        <Suspense fallback={<Spinner></Spinner>}>
          <Switch>
            <Route exact path="/" component={Launch} />
            <Route path="/allrequests" component={AllRequests} />
            <Route path="/trips" component={Trip} />
            <Route path="/tripsview" component={TripView} />
            <Route path="/requests" component={MyRequests} />

            <Route path="/logout" component={Logout} />
            <Route
              path="**"
              render={() => (
                <article>
                  <h1 className="text-danger">No Route Configured!</h1>
                  <h4 className="text-danger">
                    Please check your Route Configuration
                  </h4>
                </article>
              )}
            />
          </Switch>
        </Suspense>
      );
    }

    return (
      <Aux1>
        <CacheBuster
          currentVersion={packageInfo.version}
          isEnabled={true} //If false, the library is disabled.
          isVerboseMode={false} //If true, the library writes verbose logs to console.
          loadingComponent={<Spinner></Spinner>} //If not pass, nothing appears at the time of new version check.
          metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
        >
          <Layout>{routes}</Layout>
        </CacheBuster>
      </Aux1>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onOneLoginSignup: () => dispatch(actions.authCheckOneLogin()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
